import React from "react"

import { StimulusView } from "@views/stimulus"

export default () => {
  return (
    <StimulusView
      stimulus={{
        type: "Image",
        originalSrc:
          "https://www.researchgate.net/figure/Understandascope-C-Michael-Leunig-reproduced-with-permission_fig1_51744106",
        src: "/stimulus/understandascope-by-michael-leunig/",
        author: "Michael Leunig",
        title: "Understandascope",
      }}
    >
      <section>
        <img
          style={{
            maxWidth: 600,
          }}
          className="d-block mb-4 mx-auto"
          src="/stimulus/understandascope-Michael-Leunig.png"
          alt=""
        />
      </section>
    </StimulusView>
  )
}
