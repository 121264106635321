import React from "react"
import Col from "react-bootstrap/Col"

import ContentContainer from "@components/ContentContainer"
import SEO from "@components/SEO/seo"
import { ContentSection } from "@components/layouts/content-section"

import { Stimulus } from "@views/english-demo-1/utils/types"

interface Props {
  stimulus: Stimulus
  children: React.ReactNode
}

export const StimulusView = ({ stimulus, children }: Props) => {
  const { title = "Untitled", author, type, src, originalSrc } = stimulus

  const displaySrc = originalSrc ? originalSrc : src

  return (
    <ContentSection>
      <SEO title={title} keywords={[title, author, type]} />
      <ContentContainer rowConstrained={true} sectionClassesExtra="section">
        <Col xs={12}>
          <div className="text-center">
            <h1>{title}</h1>
            <p>by {author}</p>
          </div>
          {children}
          <section>
            <small>
              <em>
                Citation: {title} by {author}, from {displaySrc}
              </em>
            </small>
          </section>
        </Col>
      </ContentContainer>
    </ContentSection>
  )
}
